.spacer {
  aspect-ratio: 960/300;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.layer1 {
  background-image: url("./assets//layer1.svg");
}

.layer2 {
  background-image: url("./assets//layer2.svg");
}
